<template>
    <div>
        <div class="h-screen overflow-auto bg-neutral-100 dark:bg-gray-800">
            <div class="bg-white mb-8">
                <div class="mx-auto flex gap-x-4 max-w-7xl items-center justify-between p-6 lg:px-8">
                    <a
                        href="/"
                        class="-m-1.5 p-1.5">
                        <LogoImage class="h-5" />
                    </a>
                </div>
            </div>

            <main class="px-4">
                <div>
                    <!-- Your content -->
                    <ComingSoonPage
                        v-if="underConstruction"
                        hide-dashboard-link />
                    <slot v-else />
                </div>
            </main>
        </div>
    </div>
</template>

<script setup>
useHead({
    bodyAttrs: {
        class: 'h-full bg-gray-50'
    },
    htmlAttrs: {
        class: 'h-full'
    }
})
const underConstruction = computed(() => useRuntimeConfig()?.public?.underConstruction)
</script>
